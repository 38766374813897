import * as Sentry from '@sentry/remix';
import { RemixBrowser } from '@remix-run/react';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';

Sentry.init({
  // @ts-ignore We set ENV in root.tsx
  enabled: window.ENV.SENTRY_ENV === 'production',
  // @ts-ignore We set ENV in root.tsx
  environment: window.ENV.SENTRY_ENV_NAME,

  dsn: 'https://45b20af167da8768e454016e01ec4d53@o4507362522169344.ingest.de.sentry.io/4507362523545680',
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
